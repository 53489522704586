import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Button, Box, Grid, Paper, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChecklistIcon from '@mui/icons-material/Checklist';
import BayOverview from './components/BayOverview';


const Dashboard = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigateToAttendance = () => {
    navigate('/attendance'); // This will navigate to the attendance page
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4">Dashboard</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">Data Freshness ---</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {/* A wide container */}
          <Grid item xs={12} md={8} lg={9}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 240, justifyContent: 'center' }}>
                <h4>At a Glance</h4>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <List style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                      <ListItem style={{ width: 'auto' }}>
                        <ListItemIcon>
                          <ChecklistIcon style={{ fontSize: 40 }} />
                        </ListItemIcon>
                        <ListItemText primary="Last Attendance Taken" secondary="Dec 10, 2023" />
                      </ListItem>
                      {/* Repeat the ListItem for other items */}
                      <ListItem style={{ width: 'auto' }}>
                        <ListItemIcon>
                        <h1>21</h1>
                        </ListItemIcon>
                        <ListItemText primary="Working Today" secondary="4 Experts | 12 Others" />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
                {/* Some sort of action container */}
              </Paper>
            </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 240 }}>
            <h4>Quick Actions</h4>
              <Button variant="contained" color="primary"onClick={navigateToAttendance}>Log Attendance</Button>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <h4>Bay Overview</h4>
              <BayOverview />
            </Paper>
          </Grid>
        </Grid>
        {/* <Copyright sx={{ pt: 4 }} /> */}
      </Container>
    </Box>
  );
};

export default Dashboard;