import React, { useEffect, useState } from 'react';
import base from './apis/airtableConfig';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
} from '@mui/material';
import { Button } from '@mui/material';
import UpdateSkill from './components/Popups/UpdateSkill'; // Import the new component
import './index.css';

export default function TeamMembers() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [open, setOpen] = useState(false);

  const fetchTeamMembers = async () => {
    const records = await base('tblo3Le9njcbvNv9v').select({
      view: 'Grid view',
    }).firstPage();

    const newTeamMembers = records.map(record => ({
      id: record.id,
      teamMember: record.get('Team Member'),
      forkliftForMoves: record.get('Forklift For Moves'),
      trailerShuttleDriver: record.get('Trailer Shuttle Driver'),
      teamLead: record.get('Team Lead'),
      externalSiding: record.get('External Siding'),
      foamInsulation: record.get('Foam Insulation'),
      finishingStage2: record.get('Finishing Stage 2'),
      hvac: record.get('HVAC'),
      kitchens: record.get('Kitchens'),
      painting: record.get('Painting'),
      cnc: record.get('CNC'),
      finishingStage1: record.get('Finishing Stage 1'),
      plumbing: record.get('Plumbing'),
      flooring: record.get('Flooring'),
      framing: record.get('Framing'),
      finalQCTest: record.get('Final QC Test'),
      roofing: record.get('Roofing'),
      electrical: record.get('Electrical'),
      teamLeadBackup: record.get('Team Lead Backup'),

    }));

    setTeamMembers(newTeamMembers);
  };

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const handleOpen = (member) => {
    setSelectedMember(member);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right">Team Member</TableCell>
                <TableCell>Forklift For Moves</TableCell>
                <TableCell align="right">Trailer Shuttle Driver</TableCell>
                <TableCell align="right">Team Lead</TableCell>
                <TableCell align="right">External Siding</TableCell>
                <TableCell align="right">Foam Insulation</TableCell>
                <TableCell align="right">Finishing Stage 2</TableCell>
                <TableCell align="right">HVAC</TableCell>
                <TableCell align="right">Kitchens</TableCell>
                <TableCell align="right">Painting</TableCell>
                <TableCell align="right">CNC</TableCell>
                <TableCell align="right">Finishing Stage 1</TableCell>
                <TableCell align="right">Plumbing</TableCell>
                <TableCell align="right">Flooring</TableCell>
                <TableCell align="right">Framing</TableCell>
                <TableCell align="right">Final QC Test</TableCell>
                <TableCell align="right">Roofing</TableCell>
                <TableCell align="right">Electrical</TableCell>
                <TableCell align="right">Team Lead Backup</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMembers.map((member, index) => (
                <TableRow key={index}>
                  <TableCell align="right">{member.teamMember}</TableCell>
                  <TableCell align="right">{member.forkliftForMoves}</TableCell>
                  <TableCell align="right">{member.trailerShuttleDriver}</TableCell>
                  <TableCell align="right">{member.teamLead}</TableCell>
                  <TableCell align="right">{member.externalSiding}</TableCell>
                  <TableCell align="right">{member.foamInsulation}</TableCell>
                  <TableCell align="right">{member.finishingStage2}</TableCell>
                  <TableCell align="right">{member.hvac}</TableCell>
                  <TableCell align="right">{member.kitchens}</TableCell>
                  <TableCell align="right">{member.painting}</TableCell>
                  <TableCell align="right">{member.cnc}</TableCell>
                  <TableCell align="right">{member.finishingStage1}</TableCell>
                  <TableCell align="right">{member.plumbing}</TableCell>
                  <TableCell align="right">{member.flooring}</TableCell>
                  <TableCell align="right">{member.framing}</TableCell>
                  <TableCell align="right">{member.finalQCTest}</TableCell>
                  <TableCell align="right">{member.roofing}</TableCell>
                  <TableCell align="right">{member.electrical}</TableCell>
                  <TableCell align="right">{member.teamLeadBackup}</TableCell>
                  <TableCell align="right">
                    <Button variant="outlined" color="primary" onClick={() => handleOpen(member)}>
                      Update
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
         {/* Dialog outside the table */}
      <Dialog open={open} onClose={handleClose}>
        <UpdateSkill selectedMember={selectedMember} handleClose={handleClose} />
      </Dialog>
    </Grid>
  );
}