import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';

const BayOverview = () => {
  const [bayData, setBayData] = useState([]);

  useEffect(() => {
    const cachedBayDataJSON = sessionStorage.getItem('airtableData_Bays');
    if (cachedBayDataJSON) {
      const bays = JSON.parse(cachedBayDataJSON);
      const transformedData = bays.map(bay => ({
        bayName: bay.fields['Bay Name'],
        bayStage: bay.fields['Bay Stage'],
        numberOfWorkers: (bay.fields['Count (Experts Assigned)'] || 0) + (bay.fields['Count (Other Workers)'] || 0)
      }));
      setBayData(transformedData);
    }
  }, []);

  return (
    <Grid container spacing={2}>
      {bayData.map((bay, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card>
            <CardContent>
              <Typography variant="h5">{bay.bayName}</Typography>
              <Typography>Stage: {bay.bayStage}</Typography>
              <Typography>Workers: {bay.numberOfWorkers}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default BayOverview;
