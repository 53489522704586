import React, { useState, useEffect, useCallback } from 'react';
import Chip from '@mui/material/Chip';
import styles from './workers.module.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import base from '../apis/airtableConfig';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const OtherWorkers = ({ bayName, bayStage, airtableData_Bays }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOtherWorkerIds, setSelectedOtherWorkerIds] = useState([]);
  const [selectedOtherWorkerNames, setSelectedOtherWorkerNames] = useState([]);
  const [availableOtherWorkers, setAvailableOtherWorkers] = useState([]);
  const [bayRecordId, setBayRecordId] = useState(null);
  const [otherWorkersNeeded, setOtherWorkersNeeded] = useState(null); // State to store 'Other workers needed'

  // Function to fetch 'Other workers needed' from airtableData_Bays
  const fetchOtherWorkersNeeded = useCallback(() => {
    const cachedDataJSON = sessionStorage.getItem('airtableData_Bays');
    if (cachedDataJSON) {
      const data = JSON.parse(cachedDataJSON);
      const matchingBay = data.find(record => record.fields['Bay Name'] === bayName);  
      if (matchingBay) {
        const otherWorkersNeededValue = matchingBay.fields['Others Missing'];
        setOtherWorkersNeeded(otherWorkersNeededValue);
      }
    }
  }, [bayName]);

  useEffect(() => {
    fetchOtherWorkersNeeded();
  }, [fetchOtherWorkersNeeded]);

  const fetchOthersAssigned = useCallback(() => {
    const cachedDataJSON = sessionStorage.getItem('airtableData_Bays');
    if (cachedDataJSON) {
      const data = JSON.parse(cachedDataJSON);
      const matchingBay = data.find(record => record.fields['Bay Name'] === bayName);  
    if (matchingBay) {
      setBayRecordId(matchingBay.id);
      const workerIds = matchingBay.fields['Other Workers'] || [];
      setSelectedOtherWorkerIds(workerIds);
  
      // Fetch names based on these IDs
      const fetchNamesPromises = workerIds.map((id) => {
        return base('Personnel Skill Level')
          .find(id)
          .then((record) => record.get('Team Member'));
      });
  
      Promise.all(fetchNamesPromises)
        .then((names) => {
          setSelectedOtherWorkerNames(names);
        })
        .catch((error) => {
          console.error('Error fetching worker names:', error);
        });
     }
    }
  }, [bayName]);
  

  useEffect(() => {
    fetchOthersAssigned();
  }, [fetchOthersAssigned]);

  const fetchAvailableOtherWorkers = () => {
    base('Personnel Skill Level')
      .select({
        fields: ['Team Member', 'Others in Bays'],
      })
      .all()
      .then((records) => {
        const others = records.map((record) => ({
          name: record.get('Team Member'),
          id: record.id,
        }));
        setAvailableOtherWorkers(others);
      })
      .catch((error) => {
        console.error('Error fetching available other workers:', error);
      });
  };

  const handleAddClick = () => {
    fetchAvailableOtherWorkers();
    setDialogOpen(true);
  };

  const handleDialogClose = (event, workerId) => {
    setDialogOpen(false);
    if (workerId && !selectedOtherWorkerIds.includes(workerId)) {
      const selectedWorker = availableOtherWorkers.find(worker => worker.id === workerId);
      if (selectedWorker) {
        setSelectedOtherWorkerIds([...selectedOtherWorkerIds, workerId]);
        setSelectedOtherWorkerNames([...selectedOtherWorkerNames, selectedWorker.name]);
        updateAirtableRecord([...selectedOtherWorkerIds, workerId]);
      }
    }
  };
  

  const handleRemoveClick = (index) => {
    const newWorkerIds = [...selectedOtherWorkerIds];
    const newWorkerNames = [...selectedOtherWorkerNames];
  
    newWorkerIds.splice(index, 1);
    newWorkerNames.splice(index, 1);
  
    setSelectedOtherWorkerIds(newWorkerIds);
    setSelectedOtherWorkerNames(newWorkerNames);
    updateAirtableRecord(newWorkerIds);
  };

  const updateSessionCache = useCallback((newOtherWorkersAssignedIds) => {
    const cachedDataJSON = sessionStorage.getItem('airtableData_Bays');
    if (cachedDataJSON) {
      const data = JSON.parse(cachedDataJSON);
      const index = data.findIndex(record => record.id === bayRecordId);
      if (index !== -1) {
        data[index].fields['Other Workers'] = newOtherWorkersAssignedIds;
        sessionStorage.setItem('airtableData_Bays', JSON.stringify(data));
      }
    }
  }, [bayRecordId]);

  useEffect(() => {
    // Replace 'selectedOtherWorkers' with 'selectedOtherWorkerIds'
    // Assuming the session cache needs to be updated with the IDs
    updateSessionCache(selectedOtherWorkerIds);
  }, [selectedOtherWorkerIds, updateSessionCache]);
  

  const updateAirtableRecord = (newOtherWorkersAssignedIds) => {
    if (!bayRecordId) {
      console.error('No bay record ID available for update.');
      return;
    }
  
    base('Bays').update([
      {
        id: bayRecordId,
        fields: {
          'Other Workers': newOtherWorkersAssignedIds,
        },
      },
    ]).then(() => {
      base('Bays').find(bayRecordId).then(updatedRecord => {
        // Update session cache with the updated record
        const cachedDataJSON = sessionStorage.getItem('airtableData_Bays');
        if (cachedDataJSON) {
          const data = JSON.parse(cachedDataJSON);
          const index = data.findIndex(record => record.id === bayRecordId);
          if (index !== -1) {
            data[index] = updatedRecord; // Update the specific record
            sessionStorage.setItem('airtableData_Bays', JSON.stringify(data));
          }
        }
        console.log('Record and session cache updated successfully.');
      }).catch((error) => {
        console.error('Error fetching updated record from Airtable:', error);
      });
    }).catch((error) => {
      console.error('Error updating record in Airtable:', error);
    });
  };

  return (
    <div>
      <div className={styles.teamHeader}>Other Workers | Needed {otherWorkersNeeded}</div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedOtherWorkerNames.map((name, index) => (
          <Chip
            key={selectedOtherWorkerIds[index]}
            label={name}
            onDelete={() => handleRemoveClick(index)}
            style={{ margin: '4px' }}
          />
        ))}
        <Chip
          icon={<AddIcon />}
          label="Add Worker"
          onClick={handleAddClick}
          style={{ margin: '4px', cursor: 'pointer' }}
        />
      </div>
      <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <DialogTitle>Select a Worker</DialogTitle> {/* Updated dialog title */}
        <List>
          {availableOtherWorkers.map((worker) => ( // Use availableOtherWorkers here
            <ListItem
              button
              onClick={(event) => handleDialogClose(event, worker.id)}
              key={worker.id}
            >
              <ListItemText primary={worker.name} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </div>
  );
};

export default OtherWorkers;