import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Radio, RadioGroup, FormControlLabel, Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';


import base from './apis/airtableConfig'; // Import your airtableConfig component

const Attendance = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with a dayjs object

  const handleDateChange = (newDate) => {
    // Make sure newDate is a dayjs object
    if (newDate && dayjs.isDayjs(newDate)) {
      setSelectedDate(newDate);
    }
  };

  useEffect(() => {
    const cachedBayDataJSON = sessionStorage.getItem('airtableData_Bays');
  
    if (cachedBayDataJSON) {
      const bayData = JSON.parse(cachedBayDataJSON);
  
      // Create a mapping of experts to their assigned bays
      const expertToBaysMap = new Map();
      bayData.forEach(bay => {
        const bayName = bay.fields['Bay Name'];
        const experts = bay.fields['from Experts Assigned'] || [];
  
        experts.forEach(expert => {
          if (!expertToBaysMap.has(expert)) {
            expertToBaysMap.set(expert, []);
          }
          expertToBaysMap.get(expert).push(bayName);
        });
      });
  
      // Update attendance data with experts and their assigned bays
      const initialAttendanceData = Array.from(expertToBaysMap).map(([expert, bays]) => ({
        person: expert,
        baysAssigned: bays.join(', '), // Combine all bays into a single string
        status: 'Present', // Default status
        submitted: false, 

      }));
  
      setAttendanceData(initialAttendanceData);
    }
  }, []);
  

  const handleStatusChange = (index, event) => {
    const updatedAttendanceData = [...attendanceData];
    updatedAttendanceData[index].status = event.target.value;
    setAttendanceData(updatedAttendanceData);
  };

  const handleSubmitForRow = (index) => {
    const row = attendanceData[index];
    const recordToSubmit = {
      fields: {
        Person: row.person,
        Status: row.status,
        Date: selectedDate.format('YYYY-MM-DD'),
      },
    };
  
    base('Attendance').create([recordToSubmit], { typecast: true }, (err, records) => {
      if (err) {
        console.error('Error creating record:', err);
      } else {
        console.log('Attendance record created successfully:', records);
        const newAttendanceData = [...attendanceData];
        newAttendanceData[index].submitted = true;
        setAttendanceData(newAttendanceData);
      }
    });
  };
  
  

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
          label="Select Date"
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
          inputFormat="YYYY-MM-DD"
        />
      </LocalizationProvider>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Expert Name</TableCell>
              <TableCell>Bay Assigned</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {attendanceData.map((row, index) => (
              <TableRow key={index}>
                  <TableCell>{row.person}</TableCell>
                  <TableCell>{row.baysAssigned}</TableCell>
                  <TableCell>
                    <RadioGroup
                      row
                      value={row.status}
                      onChange={(event) => handleStatusChange(index, event)}
                    >
                    <FormControlLabel value="Present" control={<Radio />} label="Present" />
                    <FormControlLabel value="Late" control={<Radio />} label="Late" />
                    <FormControlLabel value="Sick" control={<Radio />} label="Sick" />
                    <FormControlLabel value="Vacation" control={<Radio />} label="Vacation" />
                    <FormControlLabel value="Personal Day" control={<Radio />} label="Personal Day" />
                    </RadioGroup>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmitForRow(index)}
                      disabled={row.submitted}
                    >
                      {row.submitted ? "Submitted" : "Submit"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
        </Table>
      </TableContainer>
    </div>
  );  
};

export default Attendance;
