import Airtable from 'airtable';

Airtable.configure({
    endpointUrl: 'https://api.airtable.com',
    apiKey: 'patG6YsJn8FnK72eP.8e5d97272b69074f9cc6b862b8dc8545af8e1167f14b3922fbffc71de7fd8b27'
});

const base = Airtable.base('appGa2r841ltOFZqm');

export default base;

