import React, { useState, useEffect } from 'react';
import styles from './BayCard.module.css';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import base from '../apis/airtableConfig'; // Import Airtable base
import ExpertWorkers from './expertWorkers'; // Adjust the path as necessary
import OtherWorkers from './otherWorkers'; // Adjust the path as necessary


const BayCard = ({ bayName, bayStage, bayRecordId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stages, setStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState(bayStage);

  // Define handleButtonClick here
  const handleButtonClick = () => {
    fetchStages(); // Fetch stages when the button is clicked
    setIsModalOpen(true);
  };

const fetchStages = async () => {
    try {
      const records = await base('Stages').select({
        view: "Grid view"
      }).firstPage();
  
      const stageData = records.map(record => ({
        id: record.id,
        name: record.fields.Name
      }));
      setStages(stageData);
    } catch (error) {
      console.error("Error fetching stages: ", error);
    }
  };
  
  const handleCloseModal = async (selectedStageId) => {
    setIsModalOpen(false);
  
    try {
      // Update the 'Current Stage' in Airtable
      await base('Bays').update([
        {
          id: bayRecordId,
          fields: {
            'Current Stage': [selectedStageId]
          }
        }
      ]);
  
      // Refresh airtableData_Bays data
      const updatedRecords = await base('Bays').select({
        view: 'Grid view',
      }).firstPage();
      
      sessionStorage.setItem('airtableData_Bays', JSON.stringify(updatedRecords));
  
      // Update local state to reflect the new stage
      const updatedStageName = stages.find(stage => stage.id === selectedStageId)?.name;
      if (updatedStageName) {
        setSelectedStage(updatedStageName);
      }
    } catch (error) {
      console.error("Error updating stage: ", error);
    }
  };
  

  return (
<div className={styles.baycard}>
        <svg
          width="81"
          height="31"
          viewBox="0 0 81 31"
          className={styles.bayImage}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path id="Vector 1" d="M80 22.0077L49.1523 2L6 29H29.7358" stroke="black" strokeWidth="3" />
        </svg>
        <div className={styles.header}>
          <div className={styles.bayName}>{bayName}</div>
          <div className={styles.flexContainer}>
          <div className={styles.buttonContainer}>
            <Chip label={selectedStage} className={styles.currentStage} />
            <Button onClick={handleButtonClick}>Update Stage</Button>
          </div>
        </div>
      </div>
      <ExpertWorkers bayName={bayName} />
      <OtherWorkers bayName={bayName} />
      <Dialog onClose={() => setIsModalOpen(false)} open={isModalOpen}>
        <DialogTitle>Set Current Stage</DialogTitle>
        <List sx={{ pt: 0 }}>
          {stages.map((stage, index) => (
            <ListItem disableGutters key={index}>
              <ListItemButton onClick={() => handleCloseModal(stage.id)}>
                <ListItemText primary={stage.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Dialog>
    </div>
);
}

export default BayCard;
