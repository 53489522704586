import React, { useState, useEffect } from 'react';
import { Paper, Typography, Button, Select, MenuItem } from '@mui/material';
import base from '../../apis/airtableConfig';

const UpdateSkill = ({ selectedMember, handleClose }) => {
  const [fields, setFields] = useState({});

  useEffect(() => {
    // Fetch fields and their values for the selected Team Member
    const fetchFields = async () => {
      const record = await base('tblo3Le9njcbvNv9v').find(selectedMember.id);
      setFields(record.fields);
    };

    fetchFields();
  }, [selectedMember]);

  const handleUpdateField = async (fieldName, value) => {
    // Perform the update for the specific field
    await base('tblo3Le9njcbvNv9v').update([
      {
        id: selectedMember.id,
        fields: {
          [fieldName]: value,
        },
      },
    ]);

    // Update the local state to reflect the change
    setFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h6">Update Skills for {selectedMember.teamMember}</Typography>
      <Typography>Please select a new status for each skill:</Typography>

      {Object.entries(fields).map(([fieldName, value], index) => (
        <div key={index} style={{ marginBottom: '10px' }}>
          <Typography>{fieldName}</Typography>
          <Select
            value={value}
            onChange={(event) => handleUpdateField(fieldName, event.target.value)}
            style={{ width: '100%' }}
          >
            <MenuItem value="Expert">Expert</MenuItem>
            <MenuItem value="Average">Average</MenuItem>
            <MenuItem value="Low">Low</MenuItem>
          </Select>
          <Button
            variant="outlined"
            onClick={() => handleUpdateField(fieldName, value)}
            style={{ marginTop: '10px' }}
          >
            Save
          </Button>
        </div>
      ))}

      <Button variant="outlined" onClick={handleClose} style={{ marginTop: '20px' }}>
        Close
      </Button>
    </Paper>
  );
};

export default UpdateSkill;
