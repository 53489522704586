import React, { useState, useEffect, useCallback } from 'react';
import styles from './workers.module.css';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import base from '../apis/airtableConfig';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const ExpertWorkers = ({ bayName, bayStage }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedExpertIds, setSelectedExpertIds] = useState([]);
  const [selectedExpertNames, setSelectedExpertNames] = useState([]);  
  const [availableExperts, setAvailableExperts] = useState([]);
  const [bayRecordId, setBayRecordId] = useState(null);
  const [expertsNeeded, setExpertsNeeded] = useState(null); // State to store 'Experts Needed'

  // Function to fetch 'Experts Needed' from sessionStorage based on 'bayStage'
  const fetchExpertsNeeded = useCallback(() => {
    const cachedDataJSON = sessionStorage.getItem('airtableData_Bays');
    if (cachedDataJSON) {
      const data = JSON.parse(cachedDataJSON);
      const matchingBay = data.find(record => record.fields['Bay Name'] === bayName);  
      if (matchingBay) {
        // Assuming 'Experts Needed' is a field in the 'Stages' table
        const expertsNeededValue = matchingBay.fields['Experts Missing'];
        setExpertsNeeded(expertsNeededValue);
      }
    }
  }, [bayName]);

  useEffect(() => {
    fetchExpertsNeeded();
  }, [fetchExpertsNeeded]);

  const fetchExpertsAssigned = useCallback(() => {
    const cachedDataJSON = sessionStorage.getItem('airtableData_Bays');
    if (cachedDataJSON) {
      const data = JSON.parse(cachedDataJSON);
      const matchingBay = data.find(record => record.fields['Bay Name'] === bayName);
      
      if (matchingBay) {
        setBayRecordId(matchingBay.id);
        const expertIds = matchingBay.fields['Experts Assigned'] || [];
        setSelectedExpertIds(expertIds);
  
        // Ensure expertIds is an array before mapping
        if (Array.isArray(expertIds)) {
          const fetchNamesPromises = expertIds.map((id) => {
            return base('Personnel Skill Level')
              .find(id)
              .then((record) => record.get('Team Member'));
          });
  
          Promise.all(fetchNamesPromises)
            .then((names) => {
              setSelectedExpertNames(names);
            })
            .catch((error) => {
              console.error('Error fetching expert names:', error);
            });
        }
      }
    }
  }, [bayName]);
  
  useEffect(() => {
    fetchExpertsAssigned();
  }, [fetchExpertsAssigned]);

  const fetchAvailableExperts = () => {
    base('Personnel Skill Level')
      .select({
        fields: ['Team Member', 'Expert in Bays'],
      })
      .all()
      .then((records) => {
        const experts = records.map((record) => ({
          name: record.get('Team Member'),
          id: record.id,
        }));
        setAvailableExperts(experts);
      })
      .catch((error) => {
        console.error('Error fetching available experts:', error);
      });
  };

  const handleAddClick = () => {
    fetchAvailableExperts();
    setDialogOpen(true);
  };

  const handleDialogClose = (event, expertId) => {
    setDialogOpen(false);
    if (expertId && !selectedExpertIds.includes(expertId)) {
        const selectedExpert = availableExperts.find(expert => expert.id === expertId);
        if (selectedExpert) {
            setSelectedExpertIds([...selectedExpertIds, expertId]);
            setSelectedExpertNames([...selectedExpertNames, selectedExpert.name]);
            updateAirtableRecord([...selectedExpertIds, expertId]);
        }
    }
};

  const handleRemoveClick = (index) => {
    const newExpertIds = [...selectedExpertIds];
    const newExpertNames = [...selectedExpertNames];

    newExpertIds.splice(index, 1);
    newExpertNames.splice(index, 1);

    setSelectedExpertIds(newExpertIds);
    setSelectedExpertNames(newExpertNames);
    updateAirtableRecord(newExpertIds);
  };

  const updateSessionCache = useCallback((newExpertsAssignedIds) => {
    const cachedDataJSON = sessionStorage.getItem('airtableData_Bays');
    if (cachedDataJSON) {
      const data = JSON.parse(cachedDataJSON);
      const index = data.findIndex(record => record.id === bayRecordId);
      if (index !== -1) {
        data[index].fields['Experts Assigned'] = newExpertsAssignedIds;
        sessionStorage.setItem('airtableData_Bays', JSON.stringify(data));
      }
    }
  }, [bayRecordId]);

  useEffect(() => {
    const newExpertsAssignedIds = selectedExpertIds.map(id => {
      const foundExpert = availableExperts.find(expert => expert.id === id);
      return foundExpert?.id;
    }).filter(id => id != null);
  
    updateSessionCache(newExpertsAssignedIds);
  }, [selectedExpertIds, availableExperts, updateSessionCache]);
  

  const updateAirtableRecord = (newExpertsAssignedIds) => {
    if (!bayRecordId) {
      console.error('No bay record ID available for update.');
      return;
    }
  
    base('Bays').update([
      {
        id: bayRecordId,
        fields: {
          'Experts Assigned': newExpertsAssignedIds,
        },
      },
    ]).then(() => {
      // After updating, re-fetch the updated record from Airtable
      base('Bays').find(bayRecordId).then(updatedRecord => {
        // Update session cache with the updated record
        const cachedDataJSON = sessionStorage.getItem('airtableData_Bays');
        if (cachedDataJSON) {
          const data = JSON.parse(cachedDataJSON);
          const index = data.findIndex(record => record.id === bayRecordId);
          if (index !== -1) {
            data[index] = updatedRecord; // Update the specific record
            sessionStorage.setItem('airtableData_Bays', JSON.stringify(data));
          }
        }
  
        console.log('Record and session cache updated successfully.');
      }).catch((error) => {
        console.error('Error fetching updated record from Airtable:', error);
      });
    }).catch((error) => {
      console.error('Error updating record in Airtable:', error);
    });
  };
  

  return (
    <div>
      <div className={styles.teamHeader}>Experts | Needed {expertsNeeded}</div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {selectedExpertNames.map((name, index) => (
              <Chip
                  key={selectedExpertIds[index]}
                  label={name}
                  onDelete={() => handleRemoveClick(index)}
                  style={{ margin: '4px' }}
              />
          ))}
          <Chip
              icon={<AddIcon />}
              label="Add Expert"
              onClick={handleAddClick}
              style={{ margin: '4px', cursor: 'pointer' }}
          />
      </div>
      <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <DialogTitle>Select an Expert</DialogTitle>
        <List>
          {availableExperts.map((expert) => (
            <ListItem
              button
              onClick={(event) => handleDialogClose(event, expert.id)}
              key={expert.id}
            >
              <ListItemText primary={expert.name} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </div>
  );
};

export default ExpertWorkers;
