import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import BayCard from './components/BayCard';
import airtableConfig from './apis/airtableConfig';

const Bays = () => {
  const [bayData, setBayData] = useState([]);
  const [stagesData, setStagesData] = useState([]);

  // Function to fetch 'Bays' data from Airtable and update sessionStorage
  const fetchAndCacheBayData = async () => {
    try {
      const bayRecords = await airtableConfig('Bays').select({
        view: 'Grid view',
      }).firstPage();

      const newBayData = bayRecords.map(record => ({
        name: record.fields['Bay Name'],
        stage: record.fields['Bay Stage'],
        expertsAssigned: record.fields['Experts Assigned'],
        otherWorkers: record.fields['Other Workers'],
        id: record.id
      }));
      setBayData(newBayData);

      sessionStorage.setItem('airtableData_Bays', JSON.stringify(bayRecords));
    } catch (error) {
      console.error("Error fetching 'Bays' data: ", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // Fetch 'Bays' data from sessionStorage or Airtable API
      const cachedBayDataJSON = sessionStorage.getItem('airtableData_Bays');

      if (cachedBayDataJSON) {
        const bayData = JSON.parse(cachedBayDataJSON);
        const newBayData = bayData.map(record => ({
          name: record.fields['Bay Name'],
          stage: record.fields['Bay Stage'],
          expertsAssigned: record.fields['Experts Assigned'],
          otherWorkers: record.fields['Other Workers'],
          id: record.id
        }));
        setBayData(newBayData);
      } else {
        // Fetch fresh data from Airtable and update sessionStorage
        fetchAndCacheBayData();
      }

      // Fetch 'Stages' data
      const cachedStagesDataJSON = sessionStorage.getItem('airtableData_Stages');

      if (cachedStagesDataJSON) {
        const stagesData = JSON.parse(cachedStagesDataJSON);
        setStagesData(stagesData);
      } else {
        try {
          const stagesRecords = await airtableConfig('Stages').select({
            view: 'Grid view',
          }).firstPage();

          setStagesData(stagesRecords);

          sessionStorage.setItem('airtableData_Stages', JSON.stringify(stagesRecords));
        } catch (error) {
          console.error("Error fetching 'Stages' data: ", error);
        }
      }
    };

    fetchData();

    const syncDataWithAirtable = () => {
      // Existing sync logic
      console.log("Syncing data with Airtable...");
    };

    const intervalId = setInterval(syncDataWithAirtable, 15000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={fetchAndCacheBayData}>
        Force Update Data
      </Button>
      <Grid container spacing={3}>
        {bayData.map(bay => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={bay.id}>
            <BayCard 
              bayName={bay.name} 
              bayStage={bay.stage} 
              expertsAssigned={bay.expertsAssigned}
              otherWorkers={bay.otherWorkers}
              bayRecordId={bay.id} 
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );  
};

export default Bays;
